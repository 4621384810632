import React, {useEffect, useState} from "react";
import Button from "./components/button";
import Input from "./components/input";
import InputPassword from "./components/input_password";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login, clear_user_module, LOGIN_SUCCESS} from "./redux/users";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const history = useHistory();
    const _login_data = useSelector((state) => state.users_module.login);
    const [data, setData] = useState({
        username: "",
        password: "",
    });

    const dispatch = useDispatch();
    useEffect(() => {
        if (_login_data.status === 200) {
            history.push("/");
        } else if (_login_data.status !== 0) {
            toast.error(_login_data.message, {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_user_module(LOGIN_SUCCESS));
        }

    }, [_login_data, history, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.username === "") {
            toast.error("Debe ingresar el usuario", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (data.password === "") {
            toast.error("Debe ingresar la contraseña", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (data.username !== "" && data.password !== "") {
            let value_login = {
                username: data.username.trim(),
                password: data.password,
            }
            dispatch(login(value_login));
        }
    };

    return (
        <div className="app-content content1">
            <div className="content-overlay"/>
            <div className="content-wrapper">
                <div className="content-body">
                    <section className="row flexbox-container">
                        <div><ToastContainer/></div>
                        <div className="col-12" style={{
                            backgroundImage: "url(/base/app-assets/images/logo/vicenza.png)",
                            backgroundRepeat: 'no-repeat',
                            overflowX: 'scroll',
                            height: '62em',
                            width: '100em',
                            backgroundColor: '#033a2a',
                        }}>
                            <div className="row m-2">
                                <div className="offset-lg-1 col-lg-5 col-md-6 mt-lg-5 div_texto">
                                    <img
                                        src={
                                            window.location.origin +
                                            "/base/app-assets/images/logo/logo_color.png"
                                        }
                                        alt="branding logo"
                                        className="img_logo"
                                    />
                                    <div className="mt-lg-5 mt-md-2 mt-1">
                                        <p className="texto_1 text_white type_text">¡Fabrica con nosotros!</p>
                                    </div>
                                    <div className="mt-lg-5 mt-md-2 mt-3">
                                        <p className="texto_2 text_white type_text">Te damos la bienvenida a la
                                            plataforma de fabricaciones de Aurus joyería.</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6 mt-lg-5">
                                    <div className="card div_create">
                                        <div className="card-content">
                                            <div className="card-body mr-2">
                                                <div className="text-center">
                                                    <p className="texto_2 text_white type_text">¡Te damos la
                                                        bienvenida!</p>
                                                </div>
                                                <div className="mt-1">
                                                    <form onSubmit={handleSubmit}>
                                                        <Input
                                                            type="text"
                                                            name="username"
                                                            id="username"
                                                            value={data.username}
                                                            onChange={(e) =>
                                                                setData({...data, username: e.target.value})
                                                            }
                                                            label="Identificación"
                                                            color_label="texto1"
                                                        />
                                                        <InputPassword
                                                            type="password"
                                                            name="password"
                                                            id="password1"
                                                            id_div1="show_hide_password_old"
                                                            id_div2="password_old"
                                                            value={data.password}
                                                            onChange={(e) =>
                                                                setData({...data, password: e.target.value})
                                                            }
                                                            label="Contraseña"
                                                            color_label="texto1"
                                                        />
                                                        <div
                                                            className="form-group align-items-center">
                                                            <div className="text-center">
                                                                <Link to="/recuperar-contrasena"
                                                                      className="card-link texto1">
                                                                    <small><u><strong>¿Olvidé mi
                                                                        contraseña?</strong></u></small>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            type="submit"
                                                            classes="btn btn-calendar-cliente glow w-100 position-relative"
                                                            text="Ingresar"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="form-group align-items-center">
                                                <div className="text-center texto1">
                                                    <small>¿No tienes una cuenta?</small><br/>
                                                    <Link to="/crear-cuenta" className="card-link texto1">
                                                        <small><u><strong>Crear una cuenta aquí</strong></u></small>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Login;
