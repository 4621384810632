import React, {useEffect, useState} from 'react';
import {activate_account, clear_user_module, ACTIVATE_ACCOUNT} from '../../redux/users';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory, useParams} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InputPassword from '../../components/input_password';
import LoadingSpinner from "../../components/loading-spinner";

const ActivateAccount = () => {
    const {user_id, token} = useParams()
    const _activate_account = useSelector((state) => state.users_module._activate_account);
    let history = useHistory();

    const dispatch = useDispatch();
    const [activate, setActivate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [form, setForm] = useState({
        new_password: '',
        confirmation_token: token,
        user_id: user_id
    });

    useEffect(() => {
        if (_activate_account.status === 200) {
            dispatch(clear_user_module(ACTIVATE_ACCOUNT));
            setActivate(true);
            setIsLoading(false);
        } else if (_activate_account.status !== 0) {
            toast.error(_activate_account.message, {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_user_module(ACTIVATE_ACCOUNT));
            setIsLoading(false);
        }
    }, [_activate_account, dispatch, history]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.new_password === '') {
            toast.error("Debe ingresar la contraseña", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        } else {
            if (form.new_password.length < 8 || form.new_password.length > 20) {
                toast.error("La contraseña debe tener un mínimo de 8 caracteres y un máximo de 20 caracteres.", {position: toast.POSITION.TOP_RIGHT});
                setIsLoading(false);
                return;
            } else {
                const mayymin = /(?:[A-Z])/.test(form.new_password) && /(?:[a-z])/.test(form.new_password);
                const numeros = /(?:\d)/.test(form.new_password);
                const especial = /(?=.*?[#?!@$ %^&*-_])/.test(form.new_password);
                const valida = mayymin && numeros && especial;
                if (!valida) {
                    toast.error("La contraseña debe incluir al menos un número, una letra mayúscula y un carácter especial.", {position: toast.POSITION.TOP_RIGHT});
                    setIsLoading(false);
                    return;
                }
            }
        }
        dispatch(activate_account(form));
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">
                <div className="content-header row"></div>
                <div className="content-body">
                    {/* reset password start */}
                    <section className="row flexbox-container">
                        <div className="col-10">
                            <div className="card bg-authentication mb-0">
                                <div className="row m-0">
                                    {/* left section-login */}
                                    <div className="col-md-12 col-12 px-0">
                                        <div className="card disable-rounded-right d-flex justify-content-center mb-0 p-2 h-100">
                                            <div className="card-header pb-1">
                                                <div className="card-title">
                                                    <h4 className="text-center mb-2">Activa tu cuenta</h4>
                                                </div>
                                            </div>
                                            <div className="card-content">
                                                <div className="card-body">
                                                    <div><ToastContainer/></div>
                                                    {
                                                        !activate ?
                                                            <form className="mb-2" onSubmit={handleSubmit}>
                                                                <div className="form-group row d-flex justify-content-center">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                                                        <img
                                                                            className="img-fluid "
                                                                            src={window.location.protocol + "//" + window.location.host + "/base/app-assets/images/pages/lock-screen.png"}
                                                                            alt="branding logo"
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 mt-3">
                                                                        <InputPassword
                                                                            onChange={handleChange}
                                                                            label="Nueva contraseña"
                                                                            type="password"
                                                                            name="new_password"
                                                                            placeholder="Nueva contraseña"
                                                                            required={true}
                                                                            id="password1"
                                                                            id_div1="show_hide_password"
                                                                            id_div2="password"
                                                                        />
                                                                        <div className='mt-1 mb-1 p-1'
                                                                             style={{background: 'rgba(251,221,188,0.65)'}}>
                                                                            <small>* La contraseña debe tener un mínimo
                                                                                de 8 caracteres y un máximo de 20
                                                                                caracteres.</small><br/>
                                                                            <small>* La contraseña debe incluir al menos
                                                                                un número, una letra mayúscula y un
                                                                                carácter especial.</small><br/>
                                                                            <small>* La contraseña no puede estar
                                                                                relacionada con el usuario.</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" disabled={isLoading}
                                                                        className="btn btn-calendar-cliente glow position-relative w-100">
                                                                    Activar mi cuenta
                                                                    <i id="icon-arrow" className="bx bx-right-arrow-alt"/>
                                                                </button>

                                                            </form>
                                                            :
                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                                    <img
                                                                        className="img-fluid "
                                                                        src={window.location.protocol + "//" + window.location.host + "/base/app-assets/images/pages/lock-screen.png"}
                                                                        alt="branding logo"
                                                                    />
                                                                    <Link
                                                                        to="/"
                                                                        className="mt-3 btn btn-calendar-cliente glow position-relative w-100">
                                                                        Iniciar sesión
                                                                        <i id="icon-arrow"
                                                                           className="bx bx-right-arrow-alt"/>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* right section image */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* reset password ends */}
                </div>
            </div>
        </div>
    )
}

export default ActivateAccount;
