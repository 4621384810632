import axios from "axios";
import {
    URLAPI,
    FAMILY_PATH,
    FAMILY_CREATE_PATH,
    FAMILY_UPDATE_PATH,
    FAMILY_ATTRIBUTE_CREATE_PATH,
    FAMILY_ATTRIBUTE_DELETE_PATH,
} from "../config";
import {LOGOUT} from './users'

const init = {
    _get_all_family: {
        data: [],
        status: 0,
        message: {},
    },
    _create_family: {
        data: {},
        status: 0,
        message: {},
    },
    _update_family: {
        data: {},
        status: 0,
        message: {},
    },
    _create_family_attribute: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_family_attribute: {
        data: {},
        status: 0,
        message: {},
    },
}

const GET_ALL_FAMILY_SUCCESS = "GET_ALL_FAMILY_SUCCESS";
export const CREATE_FAMILY_SUCCESS = "CREATE_FAMILY_SUCCESS";
export const UPDATE_FAMILY_SUCCESS = "UPDATE_FAMILY_SUCCESS";
export const CREATE_FAMILY_ATTRIBUTE_SUCCESS = "CREATE_FAMILY_ATTRIBUTE_SUCCESS";
export const DELETE_FAMILY_ATTRIBUTE_SUCCESS = "DELETE_FAMILY_ATTRIBUTE_SUCCESS";

export const family_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_FAMILY_SUCCESS:
            return {
                ...state,
                _get_all_family: action.payload,
            };
        case CREATE_FAMILY_SUCCESS:
            return {
                ...state,
                _create_family: action.payload,
            };
        case UPDATE_FAMILY_SUCCESS:
            return {
                ...state,
                _update_family: action.payload,
            };
        case CREATE_FAMILY_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                _create_family_attribute: action.payload,
            };
        case DELETE_FAMILY_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                _delete_family_attribute: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const get_all_family = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${FAMILY_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_FAMILY_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_FAMILY_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_FAMILY_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const create_family = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${FAMILY_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_FAMILY_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_FAMILY_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_FAMILY_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear",
                },
            });
        }
    }
}

export const update_family = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${FAMILY_UPDATE_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_FAMILY_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: UPDATE_FAMILY_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_FAMILY_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const create_family_attribute = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${FAMILY_ATTRIBUTE_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_FAMILY_ATTRIBUTE_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_FAMILY_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_FAMILY_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear",
                },
            });
        }
    }
}

export const delete_family_attribute = (token, id_family, id_attribute) => async (dispatch) => {
    try {
        const response = await axios.delete(`${URLAPI}${FAMILY_ATTRIBUTE_DELETE_PATH}${id_family}/${id_attribute}/`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: DELETE_FAMILY_ATTRIBUTE_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: DELETE_FAMILY_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_FAMILY_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al eliminar",
                },
            });
        }
    }
}

export const clear_status_family = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}
