import axios from "axios";
import {
    URLAPI,
    ATTRIBUTE_PATH,
    ATTRIBUTE_CREATE_PATH,
    ATTRIBUTE_UPDATE_PATH,
    ATTRIBUTE_DELETE_PATH,
    ATTRIBUTE_VALUE_CREATE_PATH,
    ATTRIBUTE_VALUE_DELETE_PATH,
} from "../config";
import {LOGOUT} from './users'

const init = {
    _get_all_attribute: {
        data: [],
        status: 0,
        message: {},
    },
    _create_attribute: {
        data: {},
        status: 0,
        message: {},
    },
    _update_attribute: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_attribute: {
        data: {},
        status: 0,
        message: {},
    },
    _create_attribute_value: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_attribute_value: {
        data: {},
        status: 0,
        message: {},
    },
}

const GET_ALL_ATTRIBUTE_SUCCESS = "GET_ALL_ATTRIBUTE_SUCCESS";
export const CREATE_ATTRIBUTE_SUCCESS = "CREATE_ATTRIBUTE_SUCCESS";
export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS";
export const DELETE_ATTRIBUTE_SUCCESS = "DELETE_ATTRIBUTE_SUCCESS";
export const CREATE_ATTRIBUTE_VALUE_SUCCESS = "CREATE_ATTRIBUTE_VALUE_SUCCESS";
export const DELETE_ATTRIBUTE_VALUE_SUCCESS = "DELETE_ATTRIBUTE_VALUE_SUCCESS";

export const attribute_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                _get_all_attribute: action.payload,
            };
        case CREATE_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                _create_attribute: action.payload,
            };
        case UPDATE_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                _update_attribute: action.payload,
            };
        case DELETE_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                _delete_attribute: action.payload,
            };
        case CREATE_ATTRIBUTE_VALUE_SUCCESS:
            return {
                ...state,
                _create_attribute_value: action.payload,
            };
        case DELETE_ATTRIBUTE_VALUE_SUCCESS:
            return {
                ...state,
                _delete_attribute_value: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const get_all_attribute = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${ATTRIBUTE_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_ATTRIBUTE_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const create_attribute = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${ATTRIBUTE_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_ATTRIBUTE_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear",
                },
            });
        }
    }
}

export const update_attribute = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${ATTRIBUTE_UPDATE_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_ATTRIBUTE_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: UPDATE_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const delete_attribute = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(`${URLAPI}${ATTRIBUTE_DELETE_PATH}${id}/`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: DELETE_ATTRIBUTE_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: DELETE_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_ATTRIBUTE_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const create_attribute_value = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${ATTRIBUTE_VALUE_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_ATTRIBUTE_VALUE_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_ATTRIBUTE_VALUE_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_ATTRIBUTE_VALUE_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear",
                },
            });
        }
    }
}

export const delete_attribute_value = (token, id_attribute_value) => async (dispatch) => {
    try {
        const response = await axios.delete(`${URLAPI}${ATTRIBUTE_VALUE_DELETE_PATH}${id_attribute_value}/`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: DELETE_ATTRIBUTE_VALUE_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: DELETE_ATTRIBUTE_VALUE_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_ATTRIBUTE_VALUE_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al eliminar",
                },
            });
        }
    }
}


export const clear_status_attribute = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}
