import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    clear_item,
    CREATE_ITEM,
    create_item
} from "../../../redux/items";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import {ReactComponent as ReactLogo} from '../../../eclipse.svg';
import {Switch, Space} from "antd";
import SelectInput from "../../../components/select";
import Select from "react-select";
import {
    clear_manufacturer,
    create_manufacturer,
    CREATE_MANUFACTURER,
    get_all_manufacturer,
} from "../../../redux/manufacturers";
import {
    clear_status_category,
    CREATE_CATEGORY_SUCCESS,
    create_category,
    get_all_category
} from "../../../redux/categories";
import Modal from "../../../components/modal";
import LoadingSpinner from '../../../components/loading-spinner';

const CreateItem = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {nombre, id} = useParams();

    const _create_item = useSelector((state) => state.item_module._create_item);
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (_create_item.status === 201) {
            dispatch(clear_item(CREATE_ITEM));
            history.push(`/catalogo/item/${nombre}/${id}`);
            setIsLoading(false);
        } else if (_create_item.status !== 0) {
            dispatch(clear_item(CREATE_ITEM));
            toast.error(_create_item.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_item, dispatch, history, token, nombre, id]);


    const _get_all_family = useSelector((state) => state.family_module._get_all_family);
    const _get_all_collection = useSelector((state) => state.collection_module._get_all_collection);
    // eslint-disable-next-line
    const [variants, setVariants] = useState([]);
    const [collections, setColections] = useState([]);
    const [collections_select, setCollectionsSelect] = useState([]);
    const [variantsModal, setVariantsModal] = useState([]);

     useEffect(() => {
        if (_get_all_collection.data.datos) {
            // eslint-disable-next-line
            let options = _get_all_collection.data.datos.map((item) => {
                // eslint-disable-next-line
                if (item.activo === true) {
                    return {
                        value: item.id,
                        label: item.nombre,
                    };
                }
            });
            options.unshift({
                value: "ALL",
                label: "TODOS",
            })
            setColections(options);
        }
    }, [_get_all_collection]);

     const handleChangeCollectionsSelect = (e) => {
        let all = e.filter(x => x.value === "ALL")
        if (all.length > 0) {
            setCollectionsSelect(all)
        } else {
            setCollectionsSelect(e);
        }
    };

    //const [valueVariant, setValueVariant] = useState([]);

    /* function add_variants() {
        let values = _get_all_family.data.datos.filter((x) => x.nombre === nombre);
        if (values.length > 0) {
            const v = []
            // eslint-disable-next-line
            values[0].atributos.map((x) => {
                v.push({[x.nombre]: 0, 'nombre': x.nombre, 'valores': x.valores});
            })
            setVariants([...variants, v]);
        }
    } */

    function add_variants_modal() {
        let values = _get_all_family.data.datos.filter((x) => x.nombre === nombre);
        if (values.length > 0) {
            const v = []
            // eslint-disable-next-line
            values[0].atributos.map((valu) => {
                v.push({[valu.nombre]: [], 'nombre': valu.nombre, 'valores': valu.valores});
            })
            setVariantsModal([...variantsModal, v]);
            setModalVariant(true);
        }
    }

    const [form, setForm] = useState({
        codigo_interno: "",
        codigo_fabricante: "",
        titulo: "",
        descripcion: "",
        id_genero: 0,
        id_categoria: 0,
        id_fabricante: 0,
        activo: true,
    });

    const [genders, setGenders] = useState([]);

    const _get_all_gender = useSelector((state) => state.gender_module._get_all_gender);

    useEffect(() => {
        if (_get_all_gender.data.datos) {
            let values = _get_all_gender.data.datos.filter((x) => x.activo === true);
            setGenders(values);
        }
    }, [_get_all_gender]);

    const [categories, setCategories] = useState([]);

    const _get_all_category = useSelector((state) => state.category_module._get_all_category);

    useEffect(() => {
        if (_get_all_category.data.datos) {
            let values = _get_all_category.data.datos.filter((x) => x.activo === true && x.familia === nombre);
            setCategories(values);
        }
    }, [_get_all_category, nombre]);

    const [manufacturers, setManufacturers] = useState([]);

    const _get_all_manufacturer = useSelector((state) => state.manufacturer_module._get_all_manufacturer);

    useEffect(() => {
        if (_get_all_manufacturer.data.datos) {
            let values = _get_all_manufacturer.data.datos.filter((x) => x.activo === true);
            setManufacturers(values);
        }
    }, [_get_all_manufacturer]);

    const handleCheckedChange = (checked) => {
        setForm({
            ...form,
            activo: checked,
        });
    };

    const handleChange = (e) => {
        if (e.target.name === "id_genero" || e.target.name === "id_categoria" || e.target.name === "id_fabricante") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleChangeVariant = (e, index, y) => {
        variants[index][y][e.target.name] = parseInt(e.target.value);
    };

    const [images, setImages] = useState([]);

    const handleImageChange = (e) => {
        setImages([
            ...images,
            e.target.files[0]
        ]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields

        if (form.codigo_interno === "") {
            toast.error("Debe ingresar el código interno del producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.codigo_fabricante === "") {
            toast.error("Debe ingresar el código del fabricante del producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.titulo === "") {
            toast.error("Debe ingresar el título del producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.descripcion === "") {
            toast.error("Debe ingresar una descripción del producto", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_genero === 0) {
            toast.error("Debe seleccionar un género para el producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.id_categoria === 0) {
            toast.error("Debe seleccionar una categoría para el producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.id_fabricante === 0) {
            toast.error("Debe seleccionar un fabricante para el producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (collections_select.length === 0) {
            toast.error("Debe seleccionar al menos una colección", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (images.length === 0) {
            toast.error("Debe seleccionar una imagen para el producto", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (variants.length === 0) {
            toast.error("Debe agregar por lo menos una variante para el producto", {position: toast.POSITION.TOP_RIGHT});
            return;
        } else {
            // eslint-disable-next-line
            variants.map((row, index) => {
                // eslint-disable-next-line
                row.map((x) => {
                    if (parseInt(x[x.nombre]) === 0) {
                        toast.error("Debe seleccionar un valor a la variante " + (index + 1).toString() + ' ' + x.nombre, {position: toast.POSITION.TOP_RIGHT});
                        setIsLoading(false);
                        // eslint-disable-next-line
                        return;
                    }
                })
            })
        }

        let all = collections_select.filter(x => x.value === "ALL")
        let value_collections = []
        if (all.length > 0) {
             value_collections = _get_all_collection.data.datos;
        } else {
            // eslint-disable-next-line
            let array = collections_select.map((item) => {
                return {
                    id: item.value,
                    nombre: item.label,
                };
            });
            value_collections = array;
        }

        let value_variants = []
        // eslint-disable-next-line
        variants.map((row, index) => {
            let attribute_values = []
            // eslint-disable-next-line
            row.map((x) => {
                attribute_values.push(x[x.nombre])
            })
            value_variants.push({'variante': index + 1, 'valores_atributo': attribute_values})
        })

        let vari = [];
        let valid = true;
        // eslint-disable-next-line
        value_variants.map((x) => {
            if (JSON.stringify(vari) !== JSON.stringify(x.valores_atributo)) {
                vari = x.valores_atributo
            } else {
                toast.error("Existen variantes repetidas, revise por favor ", {position: toast.POSITION.TOP_RIGHT});
                valid = false;
                setIsLoading(false);
                // eslint-disable-next-line
                return;
            }
        })

        if (valid === true) {
            const data = new FormData();
            images.forEach((file, i) => {
                data.append(`imagenes`, file, file.name);
            });

            data.append('codigo_interno', form.codigo_interno);
            data.append('codigo_fabricante', form.codigo_fabricante);
            data.append('titulo', form.titulo);
            data.append('descripcion', form.descripcion);
            data.append('id_genero', form.id_genero);
            data.append('id_categoria', form.id_categoria);
            data.append('id_fabricante', form.id_fabricante);
            data.append('activo', JSON.stringify(form.activo));
            data.append('colecciones', JSON.stringify(value_collections));
            data.append('variantes', JSON.stringify(value_variants));
            dispatch(create_item(token, data));
        }
    };

    const _create_manufacturer = useSelector((state) => state.manufacturer_module._create_manufacturer);

    const [modalManufacturer, setModalManufacturer] = useState(false);
    const [modalVariant, setModalVariant] = useState(false);

    const [formManufacturer, setFormManufacturer] = useState({
        identificacion: "",
        nombres: "",
    });

    const handleChangeManufacturer = (e) => {
        setFormManufacturer({
            ...formManufacturer,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (_create_manufacturer.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_manufacturer(CREATE_MANUFACTURER));
            dispatch(get_all_manufacturer(token));
            setIsLoading(false);
            setModalManufacturer(false);
        } else if (_create_manufacturer.status !== 0) {
            dispatch(clear_manufacturer(CREATE_MANUFACTURER));
            toast.error(_create_manufacturer.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_manufacturer, dispatch, history, token]);

    const handleSubmitManufacturer = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (formManufacturer.identificacion === "") {
            toast.error("Debe ingresar una identificación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (formManufacturer.nombres === "") {
            toast.error("Debe ingresar los nombres", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(create_manufacturer(token, formManufacturer));
    };

    const _create_category = useSelector((state) => state.category_module._create_category);

    const [modalCategory, setModalCategory] = useState(false);

    const [formCategory, setFormCategory] = useState({
        nombre: "",
        id_familia: id,
    });

    const handleChangeCategory = (e) => {
        setFormCategory({
            ...formCategory,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (_create_category.status === 201) {
            dispatch(clear_status_category(CREATE_CATEGORY_SUCCESS));
            dispatch(get_all_category(token));
            setIsLoading(false);
            setModalCategory(false);
        } else if (_create_category.status !== 0) {
            dispatch(clear_status_category(CREATE_CATEGORY_SUCCESS));
            toast.error(_create_category.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_category, dispatch, history, token]);


    const handleSubmitCategory = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (formCategory.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(create_category(token, formCategory));
    };

    function arrayProduct(...arrays) {
        return arrays[0].reduce((prevAccumulator, currentArray) => {
            let newAccumulator = [];
            prevAccumulator.forEach(prevAccumulatorArray => {
                currentArray.forEach(currentValue => {
                    newAccumulator.push(prevAccumulatorArray.concat(currentValue));
                });
            });
            return newAccumulator;
        }, [[]]);
    }

    const handleSubmitVariations = (e) => {
        e.preventDefault();
        let variantes = [];
        let num = 1;
        // eslint-disable-next-line
        variantsModal.map((row, index) => {
            // eslint-disable-next-line
            row.map((x) => {
                var c = x[x.nombre].length;
                if (x[x.nombre].length > 0) {
                    variantes.push(x[x.nombre]);
                    num = c * num;
                }
            })
        })

        var new_variants = arrayProduct(variantes);
        let valid = true;

        // eslint-disable-next-line
        variants.map((row, index) => {
            let attribute_values = []
            // eslint-disable-next-line
            row.map((x) => {
                attribute_values.push(x[x.nombre])
            })
            // eslint-disable-next-line
            new_variants.map((d) => {
                if (JSON.stringify(d) === JSON.stringify(attribute_values)) {
                    toast.error("Existen variantes repetidas, revise por favor ", {position: toast.POSITION.TOP_RIGHT});
                    valid = false;
                    // eslint-disable-next-line
                    return;
                }
            })
        });

        let values = _get_all_family.data.datos.filter((x) => x.nombre === nombre);

        if (valid === true) {
            for (var n of new_variants) {
                let v = [];
                for (var m of n) {
                    // eslint-disable-next-line
                    values[0].atributos.map((x) => {
                        // eslint-disable-next-line
                        x.valores.map(q => {
                            if (q.id === m) {
                                v.push({
                                    [x.nombre]: m,
                                    'nombre': x.nombre,
                                    'nombre_atributo': q.nombre,
                                    'valores': x.valores
                                });
                            }
                        })
                    })
                }
                variants.push(v);
            }
            setVariantsModal([]);
            setModalVariant(false);
        }
    };

    const handleChangeSelect = (e, index, y, z) => {
        if (z.action === 'select-option') {
            variantsModal[index][y][z.name].push(z.option.value);
        }
        if (z.action === 'remove-value') {
            const ind = variantsModal[index][y][z.name].indexOf(z.removedValue.value);
            variantsModal[index][y][z.name].splice(ind, 1);

        }
        if (z.action === 'clear') {
            // eslint-disable-next-line
            z.removedValues.map((item) => {
                const ind = variantsModal[index][y][z.name].indexOf(item.value);
                variantsModal[index][y][z.name].splice(ind, 1);
            })
        }
    };


    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Crear"
                            items={[
                                // eslint-disable-next-line
                                {label: `Item ` + `${nombre}`, link: `/catalogo/item/${nombre}/${id}`},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="ml-1">
                                        <div className="d-flex">
                                            <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {
                                                history.push(`/catalogo/item/${nombre}/${id}`);
                                            }}>
                                                <ReactCancel width={32} heght={29}/>
                                            </div>
                                            <p className="type_text text_color text_inter text_32">Nuevo producto
                                                - {nombre}</p>
                                        </div>
                                        <p className="type_text text_black text_inter text_16">Agrega las fotos del
                                            producto (mínimo una foto)</p>
                                    </div>
                                    <div className="row border_card_1 m-1">
                                        <div className="p-1">
                                            <div className="card_image" style={{
                                                width: 120,
                                                height: 120,
                                                cursor: 'pointer',
                                                position: "absolute"
                                            }}>
                                                <div className="margin_cicle">
                                                    <ReactLogo width={100} heght={100}/>

                                                </div>
                                                <p className="p_create">+</p>
                                            </div>
                                            <input type="file" onChange={handleImageChange} multiple
                                                   style={{opacity: 0, width: 120, height: 120}}/>
                                        </div>
                                        {(images) && (
                                            images.map((_) => (
                                                <div className="m-1 d-flex">
                                                    <img
                                                        src={URL.createObjectURL(_)}
                                                        alt={_.name}
                                                        width={120}
                                                        height={120}
                                                    />
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" onClick={() => {
                                                        const index = images.indexOf(_);
                                                        images.splice(index, 1);
                                                    }}>
                                                        <img
                                                            className="logo"
                                                            src="../../../../base/app-assets/images/icon/delete.png"
                                                            alt="Eliminar"
                                                            width={30}
                                                            height={30}
                                                        />
                                                    </a>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                    <div className="row mt-2" style={{margin: '0 8px'}}>
                                        <div className="col-md-9 col-sm-6 col-12">
                                            <p className="type_text text_black text_inter text_16">Agrega la información
                                                general del producto</p>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-12 text-right">
                                            <Space size={16} direction='horizontal'>
                                                <strong>Activo</strong>
                                                <Switch
                                                    checked={form.activo}
                                                    checkedChildren="SI"
                                                    unCheckedChildren="NO"
                                                    onChange={handleCheckedChange}
                                                />
                                            </Space>
                                        </div>
                                    </div>
                                    <div className="row border_card_1 m-1">
                                        <div className="col-md-4 col-12">
                                            <Input
                                                label="Código interno"
                                                name="codigo_interno"
                                                value={form.codigo_interno}
                                                onChange={handleChange}
                                                color_label="texto2"
                                            />
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <Input
                                                label="Código del fabricante"
                                                name="codigo_fabricante"
                                                value={form.codigo_fabricante}
                                                onChange={handleChange}
                                                color_label="texto2"
                                            />
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <Input
                                                label="Título"
                                                name="titulo"
                                                value={form.titulo}
                                                onChange={handleChange}
                                                color_label="texto2"
                                            />
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <Input
                                                label="Descripcion"
                                                name="descripcion"
                                                value={form.descripcion}
                                                onChange={handleChange}
                                                color_label="texto2"
                                            />
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="form-group">
                                                <small className="texto2">Género</small>
                                                <SelectInput
                                                    label="Género"
                                                    name="id_genero"
                                                    value={form.id_genero}
                                                    onChange={handleChange}
                                                    options={genders ? genders.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12 d-flex">
                                            <div className="form-group" style={{width: "90%"}}>
                                                <small className="texto2">Categoría</small>
                                                <SelectInput
                                                    label="Categoría"
                                                    name="id_categoria"
                                                    value={form.id_categoria}
                                                    onChange={handleChange}
                                                    options={categories ? categories.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                            <div className="ml-1" style={{marginTop: 27}}>
                                                <button style={{
                                                    background: '#c2a64f',
                                                    color: '#FFF',
                                                    fontSize: 32,
                                                    padding: '0 8px'
                                                }} type="button" onClick={() => {
                                                    setModalCategory(true);
                                                }}>+
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12 d-flex">
                                            <div className="form-group" style={{width: "90%"}}>
                                                <small className="texto2">Fabricante</small>
                                                <SelectInput
                                                    label="Fabricante"
                                                    name="id_fabricante"
                                                    value={form.id_fabricante}
                                                    onChange={handleChange}
                                                    options={manufacturers ? manufacturers.map((item) => ({
                                                        name: item.nombres,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                            <div className="ml-1" style={{marginTop: 27}}>
                                                <button style={{
                                                    background: '#c2a64f',
                                                    color: '#FFF',
                                                    fontSize: 32,
                                                    padding: '0 8px'
                                                }} type="button" onClick={() => {
                                                    setModalManufacturer(true);
                                                }}>+
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-12">
                                            <div className="form-group">
                                                <small className="texto2">Colecciones</small>
                                                <Select
                                                    options={collections}
                                                    isMulti={true}
                                                    onChange={handleChangeCollectionsSelect}
                                                    value={collections_select}
                                                    className="texto2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2" style={{margin: '0 8px'}}>
                                        <div className="col-md-9 col-sm-6 col-12">
                                            <p className="type_text text_black text_inter text_16">Agrega las variantes
                                                del producto. Las variantes son las diferentes medidas y pesos
                                                disponibles</p>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-12 text-right">
                                            {/* <button type="button" className="btn btn-calendar" onClick={() => {add_variants()}}>Agregar</button> */}
                                            <button type="button" className="btn btn-calendar mt-1" onClick={() => {
                                                add_variants_modal();
                                            }}>Agregar
                                            </button>
                                        </div>
                                    </div>
                                    {variants && (
                                        variants.map((row, index) => (
                                            <div className="row border_card_1 m-1">
                                                <div className="col-md-8 col-12 mt-1">
                                                    <p className="type_text text_black text_inter text_16">Variante {index + 1}</p>
                                                </div>
                                                <div className="col-md-4 col-12 mt-1 text-right">
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" onClick={() => {
                                                        const v = variants.indexOf(index);
                                                        variants.splice(v, 1);
                                                    }}>
                                                        <img
                                                            className="logo"
                                                            src="../../../../base/app-assets/images/icon/delete.png"
                                                            alt="Eliminar"
                                                            width={30}
                                                            height={30}
                                                        />
                                                    </a>
                                                </div>
                                                {row.map((x, y) => (
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <small className="texto2">{x.nombre}</small>
                                                            {/* <Select
                                                                options={x.valores}
                                                                name={x.nombre}
                                                                isMulti={true}
                                                                className="texto2"
                                                                onChange={(e, z) => {handleChangeVariant(e, z, index)}}
                                                            /> */}
                                                            <SelectInput
                                                                label={x.nombre}
                                                                name={x.nombre}
                                                                select={x.nombre_atributo}
                                                                onChange={(e) => {
                                                                    handleChangeVariant(e, index, y)
                                                                }}
                                                                options={x.valores ? x.valores.map((item) => ({
                                                                    name: item.nombre,
                                                                    id: item.id,
                                                                })) : []}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    )}
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to={`/catalogo/item/${nombre}/${id}`} className="btn btn-outline-calendar m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="calendar" disabled={isLoading}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modalManufacturer}>
                <form onSubmit={handleSubmitManufacturer}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Crear fabricante</h5>
                        <div className="form-group">
                            <Input
                                label="Identificación"
                                name="identificacion"
                                value={formManufacturer.identificacion}
                                onChange={handleChangeManufacturer}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                label="Nombres"
                                name="nombres"
                                value={formManufacturer.nombres}
                                onChange={handleChangeManufacturer}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button type="sumit" disabled={isLoading} className={`btn btn-calendar mb-1 button_div`}>Guardar</button>
                        <button type="button" onClick={() => {
                            setModalManufacturer(false);
                        }} className={`btn btn-outline-calendar button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modalCategory}>
                <form onSubmit={handleSubmitCategory}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Crear categoría</h5>
                        <div className="form-group">
                            <Input
                                label="Nombre"
                                name="nombre"
                                value={formCategory.nombre}
                                onChange={handleChangeCategory}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button type="sumit" disabled={isLoading} className={`btn btn-calendar mb-1 button_div`}>Guardar</button>
                        <button type="button" onClick={() => {
                            setModalCategory(false);
                        }} className={`btn btn-outline-calendar button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modalVariant}>
                <form onSubmit={handleSubmitVariations}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Agregar variante</h5>
                        {variantsModal && (
                            variantsModal.map((row, w) => (
                                row.map((x, y) => (
                                        <div className="form-group">
                                            <label>{x.nombre}</label>
                                            <Select
                                                options={x.valores}
                                                name={x.nombre}
                                                isMulti={true}
                                                onChange={(e, z) => {
                                                    handleChangeSelect(e, w, y, z)
                                                }}
                                            />
                                        </div>
                                    )
                                )
                            )))}

                    </div>
                    <div className="card-footer text-center">
                        <button type="sumit" disabled={isLoading} className={`btn btn-calendar mb-1 button_div`}>Agregar</button>
                        <button type="button" onClick={() => {
                            setModalVariant(false);
                            setVariantsModal([]);
                        }} className={`btn btn-outline-calendar button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Footer/>
        </div>
    );
};

export default CreateItem;
