import React, {useState, useEffect} from "react";
import route from "../pages/routes";
import {useSelector} from "react-redux";

import {Link} from "react-router-dom";

const Sidebar = () => {

    const [permisos, setPermissions] = useState([]);
    const _session_data = useSelector((state) => state.users_module._session_data);

    useEffect(() => {
        if (_session_data.data) {
            if (_session_data.data.grupo_permiso) {
                if (_session_data.data.grupo_permiso.permisos) {
                    setPermissions(_session_data.data.grupo_permiso.permisos);
                }
            }
        }
    }, [_session_data]);

    const hideMenu = () => {
        document.body.classList.remove('menu-open', 'menu-expanded');
        document.body.classList.add('menu-hide');
        document.body.style = "overflow:auto;";
    }

    return (
        <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow" data-scroll-to-active="true">
            <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                    <li className="nav-item mr-auto">
                        <a className="navbar-brand" href="/">
                            <div className="brand-logo">
                                <img
                                    className="logo"
                                    src="../../../base/app-assets/images/logo/logo_color.png"
                                    alt="logo"
                                />
                            </div>
                        </a>
                    </li>
                    <li className="nav-item nav-toggle">
                        {/*eslint-disable-next-line*/}
                        <a
                            className="nav-link modern-nav-toggle pr-0"
                            data-toggle="collapse"
                            onClick={() => hideMenu()}
                        >
                            <i className="bx bx-x d-block d-xl-none font-medium-4 primary"/>
                            <i className="toggle-icon bx bx-disc font-medium-4 d-none d-xl-block primary"
                               data-ticon="bx-disc"
                            />
                        </a>
                    </li>
                </ul>
            </div>

            <div className="main-menu-content mt-2" onClick={() => hideMenu()}>
                <ul
                    className="navigation navigation-main"
                    id="main-menu-navigation"
                    data-menu="menu-navigation"
                    data-icon-style="lines"
                    style={{marginBottom: '10mm'}}
                >
                    {/*eslint-disable-next-line*/}
                    {route.map((item, index) => {
                        if (item.show_in_menu
                            && permisos.filter(x => item.meta.permission_required.includes(x.codigo)).length > 0
                        ) {
                            return (
                                <li className="nav-item mt-1" style={{background: '#0c553f'}}>
                                    <Link to={item.path}>
                                        {" "}
                                        <i className={item.icon}/>
                                        <span className="menu-title" data-i18n="Email">
                                            {item.meta.title}
                                        </span>
                                    </Link>
                                </li>
                            );
                        } else {
                            if (item.show_in_menu && !item.meta.requiresAuth) {
                                return (
                                    <li className=" nav-item">
                                        <Link to={item.path}>
                                            {" "}
                                            <i className={item.icon}/>
                                            <span className="menu-title" data-i18n="Email">
                                                {item.meta.title}
                                            </span>
                                        </Link>
                                    </li>
                                );
                            }
                        }
                    })}
                </ul>
            </div>
        </div>
    );
};
export default Sidebar;
