import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import LoadingSpinner from '../../../components/loading-spinner';
import TextArea from "../../../components/text-area";
import {Switch, Space} from "antd";
import {
    clear_shop,
    create_shop,
    CREATE_SHOP,
    delete_shop,
    DELETE_SHOP,
    get_all_shop,
    update_shop,
    UPDATE_SHOP
} from "../../../redux/shop";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Shop = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_shop = useSelector((state) => state.shop_module._get_all_shop);
    const _create_shop = useSelector((state) => state.shop_module._create_shop);
    const _update_shop = useSelector((state) => state.shop_module._update_shop);
    const _delete_shop = useSelector((state) => state.shop_module._delete_shop);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (_get_all_shop.data.datos) {
            setData(_get_all_shop.data.datos);
        }
    }, [_get_all_shop]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal_delete, setModalDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(0);

    const [form, setForm] = useState({
        nombre: "",
        merchant_id: "",
        form_id: "",
        terminal_id: "",
        apikey: "",
        url_confirmacion: "",
        activo: true,
    });

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_shop').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "calendar",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id);
                        setForm({
                            ...form,
                            nombre: item.nombre,
                            merchant_id: item.merchant_id,
                            form_id: item.form_id,
                            terminal_id: item.terminal_id,
                            apikey: item.apikey,
                            url_confirmacion: item.url_confirmacion,
                            activo: item.activo
                        });
                    },
                })

            }
            if (permisos.filter(x => x.codigo === 'delete_shop').length > 0) {
                act.push({
                    name: "delete",
                    label: "Eliminar",
                    icon: "bx bxs-trash",
                    color: "danger",
                    onClick: (item) => {
                        setModalDelete(true);
                        setId(item.id)
                    },
                })

            }
        }
        setActions(act);
        // eslint-disable-next-line
    }, [permisos, history]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (_create_shop.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_shop(CREATE_SHOP));
            dispatch(get_all_shop(token));
            setIsLoading(false);
            setModal(false);
        } else if (_create_shop.status !== 0) {
            dispatch(clear_shop(CREATE_SHOP));
            toast.error(_create_shop.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_shop, dispatch, history, token, id]);

    useEffect(() => {
        if (_update_shop.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_shop(UPDATE_SHOP));
            dispatch(get_all_shop(token));
            setIsLoading(false);
            setModal(false);
            setId(0);
        } else if (_update_shop.status !== 0) {
            dispatch(clear_shop(UPDATE_SHOP));
            toast.error(_update_shop.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_shop, dispatch, history, token, id]);

    useEffect(() => {
        if (_delete_shop.status === 200) {
            toast.success("Eliminado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_shop(DELETE_SHOP));
            dispatch(get_all_shop(token));
            setModalDelete(false);
            setId(0);
            setIsLoading(false);
        } else if (_delete_shop.status !== 0) {
            dispatch(clear_shop(DELETE_SHOP));
            toast.error(_delete_shop.message);
            setIsLoading(false);
        }
    }, [_delete_shop, history, token, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.merchant_id === "") {
            toast.error("Debe ingresar el merchant_id", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.form_id === "") {
            toast.error("Debe ingresar el form_id", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.terminal_id === "") {
            toast.error("Debe ingresar el terminal_id", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.apikey === "") {
            toast.error("Debe ingresar el apikey", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.url_confirmacion === "") {
            toast.error("Debe ingresar la url de confirmación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        const data = new FormData();
        data.append('nombre', form.nombre);
        data.append('merchant_id', form.merchant_id);
        data.append('form_id', form.form_id);
        data.append('terminal_id', form.terminal_id);
        data.append('apikey', form.apikey);
        data.append('url_confirmacion', form.url_confirmacion);
        if (id === 0) {
            dispatch(create_shop(token, data));
        } else {
            data.append('activo', form.activo);
            dispatch(update_shop(token, id, data));
        }
    };

    const handleDelete = () => {
        setIsLoading(true);
        dispatch(delete_shop(token, id));
    };

    const handleCheckedChangeActive = (checked) => {
        setForm({
            ...form,
            activo: checked,
        });
    };


    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Lista"
                            items={[{label: "Comercios", link: "/comercios"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_shop').length > 0 ?
                                        <Button type="button" text="Crear" theme="calendar" onClick={() => {
                                            setModal(true);
                                            setForm({...form, nombre: '', merchant_id: '', form_id: '', terminal_id: '', apikey: '', url_confirmacion: ''});
                                            setId(0);
                                        }}/> : null
                                }
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha de creación",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleSubmit}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">{id === 0 ? 'Crear ' : 'Editar '} comercios</h5>
                        <div className="form-group">
                            <Input
                                label="Nombre"
                                name="nombre"
                                value={form.nombre}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                label="Merchant id"
                                name="merchant_id"
                                value={form.merchant_id}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                label="Form id"
                                name="form_id"
                                value={form.form_id}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                label="Terminal id"
                                name="terminal_id"
                                value={form.terminal_id}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <TextArea
                                label="Apikey"
                                name="apikey"
                                id="apikey"
                                onChange={handleChange}
                                value={form.apikey}
                                rows={5}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                label="Url de confirmación"
                                name="url_confirmacion"
                                value={form.url_confirmacion}
                                onChange={handleChange}
                            />
                        </div>
                        {id !== 0 &&
                            <>
                                <div className="form-group">
                                    <Space size={14} direction='horizontal'>
                                        <strong>Activo</strong>
                                        <Switch
                                            checked={form.activo}
                                            checkedChildren="SI"
                                            unCheckedChildren="NO"
                                            onChange={handleCheckedChangeActive}
                                        />
                                    </Space>
                                </div>
                            </>
                        }
                    </div>
                    <div className="card-footer text-center">
                        <button disabled={isLoading} onClick={() => {
                        }} className={`btn btn-calendar mb-1 button_div`}>Guardar
                        </button>
                        <button type="button" onClick={() => {
                            setModal(false);
                        }} className={`btn btn-outline-calendar button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modal_delete}>
                <div className="modal-content">
                    <div className="card-body text-center">
                        <h5>¿Está seguro de eliminar o inactivar el registro?</h5>
                    </div>
                    <div className="card-footer text-center">
                        <button type="button" className="btn btn-calendar mb-1 button_div" onClick={() => {
                            handleDelete()
                        }} disabled={isLoading}>
                            SI
                        </button>
                        <button type="button" className="btn btn-outline-calendar button_div" onClick={() => {
                            setModalDelete(false)
                        }}>
                            NO
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Shop;
