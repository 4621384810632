import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    UPDATE_COLLECTION_SUCCESS,
    update_collection,
    get_all_collection,
    clear_status_collection,
} from "../../../redux/collections";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import LoadingSpinner from '../../../components/loading-spinner';
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import {Switch, Space} from "antd";
import {URLAPI} from "../../../config/index";


const UpdateCollection = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const _get_all_collection = useSelector((state) => state.collection_module._get_all_collection);
    const _update_collection = useSelector((state) => state.collection_module._update_collection);
    const [isLoading, setIsLoading] = useState(false);

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        nombre: "",
        imagen: "",
        activo: false,
    });

    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    useEffect(() => {
        if (_get_all_collection.data) {
            const value = _get_all_collection.data.datos.filter((x) => x.id === parseInt(id));
            if (value.length > 0) {
                setForm({
                    nombre: value[0].nombre,
                    activo: value[0].activo,
                    imagen: value[0].imagen ? value[0].imagen : "",
                });
            }
        }
    }, [_get_all_collection, id]);

    useEffect(() => {
        if (_update_collection.status === 200) {
            dispatch(clear_status_collection(UPDATE_COLLECTION_SUCCESS));
            history.push("/colecciones");
            dispatch(get_all_collection(token));
            setIsLoading(false);
        } else if (_update_collection.status !== 0) {
            dispatch(clear_status_collection(UPDATE_COLLECTION_SUCCESS));
            toast.error(_update_collection.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_collection, dispatch, history, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        //validate fields
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        const data = new FormData();
        data.append('nombre', form.nombre);
        data.append('activo', form.activo);
        data.append('imagen', form.imagen);
        dispatch(update_collection(token, id, data));
    };

    const [permisos, setPermisos] = useState([]);
    const [updateCollection, setUpdateCollection] = useState(false);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    useEffect(() => {
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_collections').length > 0) {
                setUpdateCollection(true);
            }
        }
    }, [permisos]);

    const handleCheckedChange = (checked) => {
        setForm({
            ...form,
            activo: checked,
        });
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div><ToastContainer/></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title={updateCollection ? 'Actualizar' : 'Ver'}
                            items={[{label: "Colecciones", link: "/colecciones"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="col-12 mb-2">
                                    {!updateCollection ? (
                                        <div className="d-flex">
                                            <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {
                                                history.push(`/colecciones`);
                                            }}>
                                                <ReactCancel width={32} heght={29}/>
                                            </div>
                                            <p className="type_text text_color text_inter text_32">Ver colección</p>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="d-flex">
                                                <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {
                                                    history.push(`/colecciones`);
                                                }}>
                                                    <ReactCancel width={32} heght={29}/>
                                                </div>
                                                <p className="type_text text_color text_inter text_32">Actualizar
                                                    colección</p>
                                            </div>
                                            <p className="type_text text_black text_inter text_16">Escribe el nombre de
                                                la colección</p>
                                        </>
                                    )}
                                    <form className="card" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input
                                                    label="nombre"
                                                    name="nombre"
                                                    value={form.nombre}
                                                    onChange={handleChange}
                                                    disabled={!updateCollection}
                                                />
                                            </div>
                                            <div className="col-md-6 mt-1">
                                                <Space size={14} direction='horizontal'>
                                                    <strong>Activo</strong>
                                                    <Switch
                                                        checked={form.activo}
                                                        checkedChildren="SI"
                                                        unCheckedChildren="NO"
                                                        onChange={handleCheckedChange}
                                                    />
                                                </Space>
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    name="imagen"
                                                    label="Imagen"
                                                    type="file"
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                            {form.imagen &&
                                                <div className="col-md-6 text-center">
                                                    <img
                                                        src={
                                                            imagePreview == null
                                                                ? `${URLAPI}${form.imagen}`
                                                                : imagePreview
                                                        }
                                                        alt="imagen"
                                                        width={100}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <div className="card-footer d-flex justify-content-end">
                                            <Link to="/colecciones" className="btn btn-outline-calendar m-1">
                                                Cancelar
                                            </Link>

                                            {updateCollection && (
                                                <Button type="submit" text="Guardar" theme="calendar"
                                                        disabled={isLoading}/>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateCollection;
