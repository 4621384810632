import React, {useEffect, useState} from "react";
import {TYPE_REPORT_GENERATE, TYPE_REPORT_ORDER_GENERATE} from "../config/ws-client";
import {logout} from '../redux/users';
import {useDispatch, useSelector} from "react-redux";
import {URLAPI} from '../config/index'
import {useHistory} from "react-router-dom";
import {save_notification} from "../redux/quotation";
import LoadingSpinner from "./loading-spinner";
import {ToastContainer, toast} from "react-toastify";
import Modal from "./modal";
import Input from "./input";
import InputPassword from "./input_password";
import { update_user, UPDATE_USER, clear_user_module, update_password_user, UPDATE_PASSWORD_USER } from "../redux/users";

const Navbar = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    //const {socket} = useContext(SocketContext);
    const _get_notification = useSelector((state) => state.quotation_module._get_notification);
    const [notification, setNotification] = useState([]);
    const _session_data = useSelector((state) => state.users_module._session_data);
    // const [open, setOpen] = useState(false);
    const [modalUser, setModalUser] = useState(false);
    const [modalPasssword, setModalPasssword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [new_password1, setNewPassword1] = useState('');
    const [new_password2, setNewPassword2] = useState('');
    const [old_password, setOldPassword] = useState('');

    const [user_data, setUserData] = useState({
        id: 0,
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        telefono: "",
        grupo_permiso: {
            nombre: ""
        },
        cliente: false
    });
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    // const audio = new Audio("/base/assets/audio/audio.mp3");

    useEffect(() => {
        if (_session_data.data) {
            setUserData(_session_data.data);
        }
    }, [_session_data]);


    //sleep function
    // const sleep = (ms) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }


    // useEffect(() => {
    //     if (open) {
    //         openMenu();
    //     } else {
    //         hideMenu();
    //     }
    // }, [open])


    // const handleReceiveMessage = (message) => {
    //
    // };

    /* const handleSendMessage = () => {
        //socket on connect
        socket.send(JSON.stringify({message: "Hello World"}));
    }; */

    const handleClick = () => {
        dispatch(logout(token));
    }

    useEffect(() => {
        if (_get_notification.data) {
            setNotification(_get_notification.data);
        }
    }, [_get_notification.data]);


    const download = (url, nombre) => {
        if (url && nombre) {
            var element = document.createElement('a');
            element.target = '_BLANK';
            document.body.appendChild(element);
            element.style = "display: none";

            element.href = url;
            element.download = nombre;

            element.click();
            document.body.removeChild(element);
        }
    }

    const openMenu = () => {
        document.body.classList.remove('menu-hide', 'menu-collapsed');
        document.body.classList.add('menu-open');
        document.body.classList.add('menu-expanded');

        if (document.body.classList.contains('vertical-overlay-menu')) {
            document.body.style = "overflow:hidden;";
        }
    }

    const hideMenu = () => {
        document.body.classList.remove('menu-open', 'menu-expanded');
        document.body.classList.add('menu-hide');
        document.body.style = "overflow:auto;";
    }


    const handleClickNotification = (data) => {
        if (data.type === TYPE_REPORT_GENERATE || data.type === TYPE_REPORT_ORDER_GENERATE) {
            if (data.result) {
                download(URLAPI + data.result.url, data.result.nombre)
            }
        }

        let filter = notification;
        let final = [];
        filter.forEach(x => {
            let d = x;
            if (data.id === x.id) {
                if (d.read === false) {
                    d.read = true;
                }
            }
            final.push(d);
        })
        dispatch(save_notification(final));
        return;
    }

    const handleClickDropNotification = () => {
        let filter = notification;
        let final = [];
        filter.forEach(x => {
            let data = x;
            if (data.show === true) {
                data.show = false;
            }
            final.push(data);
        })
        setNotification(final);
        dispatch(save_notification(final));
    }

    const handleChange = (e) => {
        setUserData({
            ...user_data,
            [e.target.name]: e.target.value,
        });
    };

    const _update_user = useSelector((state) => state.users_module._update_user);

    useEffect(() => {
        if (_update_user.status === 200) {
            dispatch(clear_user_module(UPDATE_USER));
            history.push("/");
            setModalUser(false);
            setIsLoading(false);
        } else if (_update_user.status !== 0) {
            dispatch(clear_user_module(UPDATE_USER));
            setModalUser(false);
            setIsLoading(false);
        }
    }, [_update_user, dispatch, history]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (user_data.telefono === "") {
            toast.error("Debe ingresar el teléfono", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (user_data.correo === "") {
            toast.error("Debe ingresar el correo electrónico", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(update_user(token, user_data.id, {'telefono': user_data.telefono, 'correo': user_data.correo, 'activo': true}));
    }

    const _update_password_user = useSelector((state) => state.users_module._update_password_user);

    useEffect(() => {
        if (_update_password_user.status === 200) {
            dispatch(clear_user_module(UPDATE_PASSWORD_USER));
            history.push("/");
            setModalPasssword(false);
            setIsLoading(false);
            setOldPassword('');
            setNewPassword1('');
            setNewPassword2('');
            dispatch(logout(token));
        } else if (_update_password_user.status !== 0) {
            dispatch(clear_user_module(UPDATE_PASSWORD_USER));
            toast.error(_update_password_user.message, {position: toast.POSITION.TOP_RIGHT});
            //setModalPasssword(false);
            setIsLoading(false);
        }
    }, [_update_password_user, dispatch, history, token]);
    
    const handleSubmitPassword = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (old_password === "") {
            toast.error("Debe ingresar la contraseña actual", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (new_password1 === "") {
            toast.error("Debe ingresar la nueva contraseña", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        } else {
            if (new_password1.length < 8 || new_password1.length > 20) {
                toast.error("La contraseña debe tener un mínimo de 8 caracteres y un máximo de 20 caracteres.", {position: toast.POSITION.TOP_RIGHT});
                setIsLoading(false);
                return;
            } else {
                const mayymin = /(?:[A-Z])/.test(new_password1) && /(?:[a-z])/.test(new_password1);
                const numeros = /(?:\d)/.test(new_password1);
                const especial = /(?=.*?[#?!@$ %^&*-_])/.test(new_password1);
                const valida = mayymin && numeros && especial;
                if (!valida) {
                    toast.error("La contraseña debe incluir al menos un número, una letra mayúscula y un carácter especial.", {position: toast.POSITION.TOP_RIGHT});
                    return;
                }
            }
        }
        if (new_password2 === "") {
            toast.error("Debe ingresar la confirmación de la nueva contraseña", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (new_password1 !== new_password2) {
            toast.error("Las contraseña nueva y su confirmación no coinciden", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(update_password_user(token, user_data.id, {'old_password': old_password, 'new_password1': new_password1, 'new_password2': new_password2}));
    }

    return (
        <>
            <nav className="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu color" style={{paddingBottom: 8}}>
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div className="navbar-collapse" id="navbar-mobile">
                            {!user_data.cliente ? (
                                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                                    <ul className="nav navbar-nav">
                                        <li className="nav-item mobile-menu d-xl-none mr-auto"
                                            onClick={() => openMenu()}
                                        >
                                            {/*eslint-disable-next-line*/}
                                            <a
                                                className="nav-link nav-menu-main menu-toggle hidden-xs"
                                                href="#"
                                                onClick={() => hideMenu()}
                                            >
                                                <i className="ficon bx bx-menu"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="nav navbar-nav bookmark-icons">

                                    </ul>
                                </div>
                            ) : (
                                <>
                                    <div className="bookmark-wrapper div-item text-center">
                                        <a className="navbar-brand" href="/">
                                            <div className="brand-logo">
                                                <img
                                                    className="logo"
                                                    src="../../../base/app-assets/images/logo/logo_color.png"
                                                    alt="logo"
                                                    width={97.5}
                                                    height={80}
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="bookmark-wrapper align-items-center" style={{width: '90%'}}>
                                        <ul className="nav_client">
                                            <li>
                                                {/*eslint-disable-next-line */}
                                                <a id="catalogo" className="type_text text_black text_16 active" style={{cursor: 'pointer'}} onClick={() => {
                                                    document.getElementById("catalogo").className = "type_text text_black text_16 active";
                                                    document.getElementById("cotizaciones").className = "type_text text_black text_16";
                                                    document.getElementById("fabricaciones").className = "type_text text_black text_16";
                                                    document.getElementById("carrito").className = "type_text text_black text_16";
                                                    history.push("/")
                                                }}><i className="bx bx-book"/> Catálogo</a>
                                            </li>
                                            <li>
                                                {/*eslint-disable-next-line */}
                                                <a id="cotizaciones" className="type_text text_black text_16" style={{cursor: 'pointer'}} onClick={() => {
                                                    document.getElementById("catalogo").className = "type_text text_black text_16";
                                                    document.getElementById("cotizaciones").className = "type_text text_black text_16 active";
                                                    document.getElementById("fabricaciones").className = "type_text text_black text_16";
                                                    document.getElementById("carrito").className = "type_text text_black text_16";
                                                    history.push("/mis-cotizaciones")
                                                }}><i className="bx bx-file"/> Mis cotizaciones</a>
                                            </li>
                                            <li>
                                                {/*eslint-disable-next-line */}
                                                <a id="fabricaciones" className="type_text text_black text_16" style={{cursor: 'pointer'}} onClick={() => {
                                                    document.getElementById("catalogo").className = "type_text text_black text_16";
                                                    document.getElementById("cotizaciones").className = "type_text text_black text_16";
                                                    document.getElementById("fabricaciones").className = "type_text text_black text_16 active";
                                                    document.getElementById("carrito").className = "type_text text_black text_16";
                                                    history.push("/mis-fabricaciones")
                                                }}><i className="bx bxs-watch"/> Mis fabricaciones</a>
                                            </li>
                                            <li>
                                                {/*eslint-disable-next-line */}
                                                <a id="carrito" className="type_text text_black text_16" style={{cursor: 'pointer'}} onClick={() => {
                                                    document.getElementById("catalogo").className = "type_text text_black text_16";
                                                    document.getElementById("cotizaciones").className = "type_text text_black text_16";
                                                    document.getElementById("fabricaciones").className = "type_text text_black text_16";
                                                    document.getElementById("carrito").className = "type_text text_black text_16 active";
                                                    history.push("/carrito-compra")
                                                }}><i className="bx bx-cart"/> Carrito</a>
                                            </li>
                                        </ul>
                                    </div>
                                </>

                            )}
                            <ul className="nav navbar-nav float-right">
                                {!user_data.cliente && (
                                    <>
                                        <li className="nav-item nav-search">
                                            {/*eslint-disable-next-line*/}
                                            <a className="nav-link nav-link-search">
                                                <i className="ficon bx bx-search"></i>
                                            </a>
                                            <div className="search-input">
                                                <div className="search-input-icon">
                                                    <i className="bx bx-search primary"></i>
                                                </div>
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="Explore Frest..."
                                                    tabIndex="-1"
                                                    data-search="template-search"
                                                />
                                                <div className="search-input-close">
                                                    <i className="bx bx-x"></i>
                                                </div>
                                                <ul className="search-list"></ul>
                                            </div>
                                        </li>
                                        <li className="dropdown dropdown-notification nav-item">
                                            {/*eslint-disable-next-line*/}
                                            <a
                                                className="nav-link nav-link-label"
                                                href="#"
                                                data-toggle="dropdown"
                                                onClick={handleClickDropNotification}
                                            >
                                                <i className="ficon bx bx-bell bx-tada bx-flip-horizontal"></i>
                                                {notification.filter(x => x.show === true).length > 0 && (
                                                    <span className="badge badge-pill badge-danger badge-up">
                                                        {notification.filter(x => x.show === true).length}
                                                    </span>
                                                )}
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                                <li className="dropdown-menu-header">
                                                    <div className="dropdown-header px-1 py-75 d-flex justify-content-between">
                                                        <span className="notification-title">
                                                        {notification.length} Nuevas notificaciones
                                                        </span>
                                                    </div>
                                                </li>
                                                <li className="scrollable-container media-list read-notification cursor-pointer"
                                                    style={{overflow: 'scroll', '-webkit-overflow-scrolling': 'touch'}}>

                                                    {notification.length !== 0 ? notification.reverse().map((notification, index) => (
                                                        <>
                                                            {/*eslint-disable-next-line*/}
                                                            <a className={`d-flex justify-content-between ${notification.read === true ? '' : 'read-notification'}`}
                                                            href="#" onClick={() => handleClickNotification(notification)}>
                                                                <div className="media d-flex align-items-center">
                                                                    <div className="media-left pr-0">
                                                                        <div className="avatar mr-1 m-0">
                                                                        </div>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="media-heading">

                                                                            <span className="text-bold-500">{notification.title}</span>
                                                                            {notification.message}
                                                                        </h6>
                                                                        <small className="notification-text">
                                                                            {notification.date}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </>
                                                    )) : null}
                                                </li>

                                                <li className="dropdown-menu-footer">
                                                    {/*eslint-disable-next-line*/}
                                                    <a
                                                        className="dropdown-item p-50 text-primary justify-content-center"
                                                        href="#"
                                                    >
                                                        Leer todas las notificaciones
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )}
                                <li className="dropdown dropdown-user nav-item">
                                    {/*eslint-disable-next-line*/}
                                    <a
                                        className="dropdown-toggle nav-link dropdown-user-link"
                                        href="#"
                                        data-toggle="dropdown"
                                    >
                                        {!user_data.cliente ?(
                                            <div className="user-nav d-sm-flex d-none">
                                                <span className="user-name">{user_data.nombres + " " + user_data.apellidos}</span>
                                                <span
                                                    className="user-status text-muted">{user_data.grupo_permiso !== undefined ? user_data.grupo_permiso.nombre : ""}</span>

                                            </div>
                                        ) : (
                                            <div className="user-nav d-sm-flex d-none">
                                                <span className="user-name">Hola {user_data.nombres + " " + user_data.apellidos}</span>
                                            </div>
                                        )}
                                        <span>
                                            <i className="bx bx-user"></i>
                                        </span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right pb-0">

                                        <div className="dropdown-divider mb-0"></div>
                                        {user_data.cliente &&(
                                            <>
                                                {/*eslint-disable-next-line*/}
                                                <a className="dropdown-item" href="#" onClick={() => {setModalUser(true)}}>
                                                    <i className="bx bx-user mr-50"></i> Editar usuario
                                                </a>
                                                {/*eslint-disable-next-line*/}
                                                <a className="dropdown-item" href="#" onClick={() => {
                                                    setOldPassword('');
                                                    setNewPassword1('');
                                                    setNewPassword2('');
                                                    setModalPasssword(true);
                                                }}>
                                                    <i className="bx bxs-key mr-50"></i> Cambiar contraseña
                                                </a>
                                            </>
                                        )}
                                        {/*eslint-disable-next-line*/}
                                        <a className="dropdown-item" href="#" onClick={handleClick}>
                                            <i className="bx bx-power-off mr-50"></i> Logout
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <Modal className="modal-main div-modal" show={modalUser}>
                <form onSubmit={handleSubmit}>
                    <div><ToastContainer/></div>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Editar usuario</h5>
                        <div className="form-group">
                            <Input
                                label="Nombres"
                                name="nombres"
                                value={user_data.nombres}
                                disabled={true}
                            />
                            <Input
                                label="Apellidos"
                                name="apellidos"
                                value={user_data.apellidos}
                                disabled={true}
                            />
                            <Input
                                label="Nombre visible"
                                name="usuario"
                                value={user_data.usuario}
                                disabled={true}
                            />
                            <Input
                                label="Teléfono"
                                name="telefono"
                                value={user_data.telefono}
                                onChange={handleChange}
                            />
                            <Input
                                label="Dirección de correo electrónico"
                                name="correo"
                                value={user_data.correo}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button onClick={() => {}} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Guardar</button>
                        <button type="button" onClick={() => {setModalUser(false);}} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar</button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modalPasssword}>
                <form onSubmit={handleSubmitPassword}>
                    <div><ToastContainer/></div>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Cambiar contraseña</h5>
                        <div className="form-group">
                            <InputPassword
                                type="password"
                                name="old_password"
                                id="old_password"
                                id_div1="show_hide_password_old"
                                id_div2="password_old"
                                label="Contraseña actual"
                                value={old_password}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <InputPassword
                                type="password"
                                name="new_password1"
                                id="new_password1"
                                id_div1="show_hide_password"
                                id_div2="password"
                                label="Nueva contraseña"
                                value={new_password1}
                                onChange={(e) => setNewPassword1(e.target.value)}
                            />
                            <div className='mt-1 mb-1 p-1' style={{background: 'rgba(251,221,188,0.65)'}}>
                                <small>* La contraseña debe tener un mínimo de 8 caracteres y un máximo de 20
                                    caracteres.</small><br/>
                                <small>* La contraseña debe incluir al menos un número, una letra mayúscula y un
                                    carácter especial.</small>
                                <small>* La contraseña no puede estar relacionada con el usuario.</small>
                            </div>
                            <InputPassword
                                type="password"
                                name="new_password2"
                                id="new_password2"
                                id_div1="show_hide_password1"
                                id_div2="password1"
                                label="Confirmar contraseña"
                                value={new_password2}
                                onChange={(e) => setNewPassword2(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button onClick={() => {
                        }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Guardar
                        </button>
                        <button type="button" onClick={() => {
                            setModalPasssword(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
        </>

    );
};

export default Navbar;
